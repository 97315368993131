<template>
  <div>
    版本号：<el-input v-model="value" style="width:200px;margin-right:20px"></el-input>
    <el-button type="primary" @click="submit">提交</el-button>
  </div>
</template>

<script>
import { getVersion, setVersion } from '@/api/appSetting.js'
export default {
  name: 'version',
  data() {
    return {
      value: ''
    }
  },
  created() {
    getVersion().then(res => {
      this.value = res.data || ''
    })
  },
  methods: {
    submit() {
      if (!this.value) {
        this.$message.warning('请输入版本号')
        return
      }
      setVersion({ version: this.value }).then(res => {
        if (res.code == 200) {
          this.$message.success('修改成功')
        } else {
          this.$message.error('修改失败')
        }
      })
    }
  }
}
</script>

<style>
</style>